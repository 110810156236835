<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol :md="tipo_envio != 'login' ? 10 : 6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @keyup.enter="login()">
                  <h1>Iniciar Sesion</h1>
                  <h1 v-if="$can('write-stuff')">Probando ando</h1>
                  <p class="text-muted">Inicia Sesion Con Tu Cuenta</p>
                  <template>
                    <CAlert
                      :show.sync="dismissCountDown"
                      closeButton
                      color="danger"
                      fade
                    >
                      <span v-html="mensajeError"></span>
                      <strong
                        >Borrando Mensaje en {{ dismissCountDown }}</strong
                      >
                      segundos...
                    </CAlert>
                  </template>
                  <CInput
                    placeholder="Email"
                    type="email"
                    autocomplete="off"
                    v-model="form.email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="off"
                    v-model="form.password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton color="primary" class="px-4" @click="login()"
                        >Iniciar Sesion</CButton
                      >
                    </CCol>
                    
                    <CCol col="12" class="text-left">
                      <br>
                      <router-link :to="'/restablecer/clave'">
                        ¿Olvido su contraseña?
                      </router-link>
                    </CCol>
                    <!--<CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton>
                      <CButton color="link" @click="getuser()" class="d-lg-none">Get User</CButton>
                    </CCol>-->
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <template v-if="cuenta_creada == 'NO' && tipo_envio != 'login'">
              <CCard
                color="primary"
                text-color="white"
                class="text-center py-5"
                body-wrapper
              >
                <CCardBody>
                  <h3>Crea Tu Cuenta de SMS Gratis Ahora</h3>
                  <!--<div v-if="$can('edit posts')">You can edit posts.</div>-->
                  <p>
                    SMS MASIVO Contacte a todos sus clientes y proveedores en
                    segundos usando nuestros servicios de envío de mensajes de
                    texto masivo, SMS con link a Whatsapp y SMS multimedia.
                    Comunicación masiva de alta efectividad y bajo costo.
                  </p>
                  <!---->
                  <router-link to="/registrar">
                    <CButton color="light" variant="outline" size="lg">
                      Registrate Ahora
                    </CButton>
                  </router-link>
                </CCardBody>
              </CCard>
            </template>
            <template v-if="cuenta_creada == 'OK' && tipo_envio != 'login'">
              <CCard
                color="success"
                text-color="white"
                class="text-center py-5"
                body-wrapper
              >
                <CCardBody>
                  <h3>
                    Felicidades Ya Cuentas con una Cuenta de SMS Activada, la
                    Información fue Enviada al Correo Electronico
                  </h3>
                  <!--<div v-if="$can('edit posts')">You can edit posts.</div>-->
                  <p>
                    SMS MASIVO Contacte a todos sus clientes y proveedores en
                    segundos usando nuestros servicios de envío de mensajes de
                    texto masivo, SMS con link a Whatsapp y SMS multimedia.
                    Comunicación masiva de alta efectividad y bajo costo.
                  </p>
                  <!--<router-link to="/registrar"> <CButton color="light" variant="outline" size="lg"> Registrate Ahora </CButton> </router-link>-->
                </CCardBody>
              </CCard>
            </template>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      mensajeError: "",
      dismissCountDown: 0,
      tipo_envio: null,
      cuenta_creada: "NO",
    };
  },
  computed: {
    mensajeRegistrado() {
      return this.$store.state.crearcuentasms.mensajeRegistrado;
    },
  },
  methods: {
    login() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$store
        .dispatch("auth/login", this.form)
        .then((response) => {
          localStorage.setItem("cuenta_creada", "NO");
          if (response == undefined) {
            //this.$router.push({ path: 'dashboard' })
            localStorage.setItem("tipo_login", "login");
            this.$store.dispatch("auth/noticias_modal", "visible");
            this.$router.push({ name: "Dashboard" });
          }
          let error = response;
          let mensajeError = "";
          if (error.response) {
            let dataError = error.response.data;
            if (error.response.status == 404) {
              mensajeError = `${dataError.message[0]} \n`;
              this.mensajeError = mensajeError;
              this.dismissCountDown = 60;
            } else if (error.response.status == 201) {
              localStorage.setItem("tipo_login", "login");
              this.$router.push({ name: "Dashboard" });
              //this.$router.push({ path: 'dashboard' })
            } else {
              this.mensajeError =
                "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
              this.dismissCountDown = 60;
            }
          }
        })
        .catch((error) => {
          localStorage.setItem("cuenta_creada", "NO");
          let mensajeError = "";
          if (error.response) {
            let dataError = error.response.data;
            if (error.response.status == 404) {
              mensajeError = `${dataError.message[0]} \n`;
              this.mensajeError = mensajeError;
              this.dismissCountDown = 60;
            } else {
              this.mensajeError =
                "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
              this.dismissCountDown = 60;
            }
          }
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    validaloginReseller() {
      const name = this.$route.name; //envio_login login
      localStorage.setItem("tipo_login", name);
      this.tipo_envio = name;
      //console.log(id);
    },
  },
  mounted() {
    this.validaloginReseller();
    let cuenta_creada = localStorage.getItem("cuenta_creada");
    if (cuenta_creada == "OK") {
      this.cuenta_creada = "OK";
      //localStorage.setItem('cuenta_creada','NO');
    } else {
      localStorage.setItem("cuenta_creada", "NO");
    }
  },
};
</script>
